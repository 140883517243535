/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import loadable from '@loadable/component';
import { bool, object } from 'prop-types';
import { useSelector } from 'react-redux';
import mbpLogger from 'mbp-logger';
import cookies from '../../../../helpers/common/storage/cookies';

import { getBrandName } from '../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getIsAuthenticatedStatus } from '../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import useSalesforcePersonalizedContentQuery from '../../../../helpers/Personalization/useSalesforcePersonalizedContentQuery';
import ComponentErrorBoundary from '../../EnterpriseID/Common/ComponentErrorBoundary/ComponentErrorBoundary';

const LoadableGuestUserGreetingContainer = loadable(() => import(/* webpackChunkName: "GuestUserGreetingContainer" */ './GuestUserGreeting/GuestUserGreetingContainer'));
const LoadableRegisterUserGreetingContainer = loadable(() => import(/* webpackChunkName: "RegisterUserGreetingContainer" */ './RegisterUserGreeting/RegisterUserGreetingContainer'));
function PersonalizedUserGreetingContainer({ blockData, isCollectionPage }) {
    const cmsData = blockData?.reference?.length > 0 ? blockData?.reference[0] : null;

    const userType = cmsData?.user_type || '';
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);

    switch (userType) {
        case 'guest':
            return !isAuthenticated ? <PersonalizedUserGreetingContainerQuery cmsData={cmsData} isCollectionPage={isCollectionPage} /> : null;
        case 'registered':
            return <LoadableRegisterUserGreetingContainer cmsData={cmsData} />;
        default:
            return null;
    }
}

PersonalizedUserGreetingContainer.propTypes = {
    blockData: object.isRequired,
    isCollectionPage: bool,
};

PersonalizedUserGreetingContainer.defaultProps = {
    isCollectionPage: false,
};

export const PersonalizedUserGreetingContainerQuery = ({
    isCollectionPage,
}) => {
    const content_type = 'personalized_login_modules';
    const brand = useSelector(getBrandName) || '1800flowers';
    const interactionName = `${brand} - Get Campaign - Login module`;
    const persistedUserProfile = cookies.get('persisted_user_profile');

    const {
        loading, error, data, variables,
    } = useSalesforcePersonalizedContentQuery({
        queryName: 'PersonalizedUserGreeting',
        interactionName,
        targeting: {
            pageType: 'home',
        },
        isSessionHasAccount: !!persistedUserProfile,
    });

    if (loading || variables?.skip) {
        return null;
    }

    if (error) {
        mbpLogger.logError({
            function: `PersonalizedUserGreetingContainerQuery - content_type = ${content_type} interactionName = ${interactionName}`,
            message: 'Error loading API data',
            appName: process.env.npm_package_name,
            module: 'PersonalizedContent',
            jsError: error,
        });
        return null;
    }

    if (data && data?.contentSF?.content) {
        const sfCampaignResponse = data?.contentSF;
        const sfCMSData = sfCampaignResponse?.content;
        return (
            <ComponentErrorBoundary function="LoadableGuestUserGreetingContainer" module="personalized_login_modules">
                <LoadableGuestUserGreetingContainer cmsData={sfCMSData} isCollectionPage={isCollectionPage} salesForceCampaignResponse={sfCampaignResponse} />
            </ComponentErrorBoundary>
        );
    }
    return null;
};

PersonalizedUserGreetingContainerQuery.propTypes = {
    isCollectionPage: bool,
};

PersonalizedUserGreetingContainerQuery.defaultProps = {
    isCollectionPage: false,
};

export default PersonalizedUserGreetingContainer;
